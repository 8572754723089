import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

window.$ = null;

import.meta.glob([
    '../assets/**',
]);

router.on('navigate', (event) => {
    let carActionLabel;
    if ('car' in event.detail.page.props) {
        carActionLabel = event.detail.page.props.car.action_label;
    }
    window.utag && window.utag.view({
        pageUrl: event.target.URL,
        url_of_page: event.target.URL,
        brand: 'cupra',
        car_action_label: carActionLabel,
    });
});

createInertiaApp({
    title: (title) => title ? `${title} | ${appName}` : appName,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        if (import.meta.env.PROD) {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                environment: import.meta.env.VITE_APP_ENV,
                integrations: [new Sentry.Integrations.Breadcrumbs({ console: !import.meta.env.PROD })],
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
                allowUrls: [/cupraofficial\.nl/, /cupraunofficial\.nl/, /netlify\.app/]
            });
        }

        app
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
